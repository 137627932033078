.hero {
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;

}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-text h1 {
    font-size: 3rem;
    font-weight: 800;
    background-color: White;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p {
    font-size: 1.3rem;
    color: white;
    padding: 0.5rem 0 0 2rem 0;
}

.hero-text .show {
    text-decoration: none;
    background: white;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: black;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    margin-top: 10px;
}

.hero-text .hide {

    display: none;
}


@media screen and (max-width: 555px) {
    .hero-text h1 {
        font-size: 2rem;
        padding: 10px 20px;
    }

    .hero-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }
    .hero-text .show{
        padding: 0.6rem 1.1rem;
        font-size: 1rem;
        border-radius: 6px;
        font-size: 1.1rem;
        font-weight: bold;
        color: black;
        width: fit-content;
    }

}