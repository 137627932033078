.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 20px;
}
h1 {
  font-size: 1.5rem; 
  text-align: center; 
}
.testimonial-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s, color 0.3s; /* Added transition property */
}

.testimonial-card:hover {
  background-color: #013d65; /* Changed background color to solid blue */
  color: #ffffff; /* Changed font color to white */
}

.testimonial-image img {
  width: 100%;
  border-radius: 50%;
  max-width: 150px;
}

.testimonial-stars {
  color: gold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial-author {
  font-weight: bold;
  color: black;
}
.testimonial-author:hover{
  font-weight: bold;
  color: white;
}

/* Responsive styles */
@media (max-width: 768px) {
  .testimonial-card {
    max-width: 100%;
  }
  .testimonial-author{
    color: black;
  }
 
  .testemonials-h1{
    font-size: 25px;
  }
  h1 {
    font-size: 1.5rem; 
    text-align: center; 
  }
}