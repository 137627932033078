/* TrustedCustomers.css */

.trusted-customers {
  padding: 40px 20px;
  background-color: #f8f8f8;
}

.trusted-customers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.trusted-customers-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
  max-width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.trusted-customers-image img {
  width: 100%; /* Set image width to 100% */
  max-width: 500px; /* Set maximum width for larger screens */
  border-radius: 50%;
}

.trusted-customers-text {
  margin-top: 20px;
}

.trusted-customers-text h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.trusted-customers-text p {
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Responsive styles */
@media (max-width: 768px) {
  .trusted-customers-container {
    flex-direction: row; /* Changed flex direction to row for smaller screens */
    flex-wrap: wrap; /* Allow wrapping of cards */
    justify-content: space-between; /* Evenly distribute cards horizontally */
  }

  .trusted-customers-card {
    max-width: 500px; 
    max-height: 100px;
    margin: 0px; /* Reduced margin for small screens */
    flex: 0 0 calc(33.33% - 10px); /* Set cards to take up 33.33% width and adjust for margin */
    background-color: White;
    border-radius: none;
    box-shadow: none;
  }

  .trusted-customers-image img {
    max-width: 100px; 
  }

  .trusted-customers-text h3 {
    padding-top: 5px;
    font-size: 9px;
    color: #000000;
   
  }
}
