.Airportride {
    padding-top: 60px;
    margin: 4rem 6rem;
    color: black;
    text-align: center;
  }
  
  .Airportride h1 {
    font-size: 3rem;
  }
  
  .Airportridecard {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 10px;
  }
  
  .t-card {
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 15px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .t-image {
    height: 250px;
    overflow: hidden;
    border-radius: 7px;
  }
  
  .t-image img {
    width: 90%;
    height: 80%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
  }
  
  .t-image:hover img {
    transform: scale(1.3);
  }
  
  .t-card h4 {
    align-items: center;
    font-size: 1.3rem;
    padding: 0.9rem 0 0.2rem 0;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
  }
  
  .whatsapp-link,
  .book-online-btn {
    text-decoration: none;
    padding: 10px;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    width: min-content;
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }
  
  .whatsapp-link {
    background: #25d366;
    border: 2px solid #25d366;
  }
  
  .whatsapp-link:hover {
    background: #075e54;
    border: 2px solid #075e54;
  }
  
  .book-online-btn {
    background: #007bff;
    border: 2px solid #007bff;
  }
  
  .book-online-btn:hover {
    background: #0056b3;
    border: 2px solid #0056b3;
  }
  .trip-details{
  font-size: medium;
  font-weight: normal;
  margin: 5px;
  }
  @media screen and (max-width: 1024px) {
    .Airportridecard {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media screen and (max-width: 768px) {
    .Airportride {
      margin: 4rem 2rem;
    }
  
    .Airportridecard {
      display: flex;
      flex-direction: column;
    }
  
    .t-card {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  
    .button-container {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .whatsapp-link,
    .book-online-btn {
      margin-top: 0.5rem;
      padding: 0.6rem 1.1rem;
      font-size: 1rem;
      border-radius: 6px;
    }
  
    .whatsapp-link:hover,
    .book-online-btn:hover {
      margin-top: 0.5rem;
      padding: 0.6rem 1.1rem;
      font-size: 1rem;
      border-radius: 6px;
      background: rgb(0, 47, 201);
      color: white;
      border: none;
    }
  }