.gallery {
  width: 100%;
  overflow: hidden;
}

.gallery-container {
  display: flex;
  width: 100%;
  animation: slide 10s infinite linear;
}

.gallery-item {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  margin-right: 20px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}