.Navitems{
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.25);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    transition: top 0.3s; /* Transition effect when sliding down (and up) */
}
.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .logo {
    cursor: pointer;
    max-width: 100%;
    height: auto;
    max-height: 150px;
    padding-top:10px ;
  } 

.nav_menu{
    display: grid;
    grid-template-columns: repeat(6 ,auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}
.nav-links{
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1rem;
    white-space: nowrap;
}

.nav-links i{
    padding-right: 10px;
}

.nav-links:hover{
    background-color: rgb(0, 47, 201);
    color: white;
    border-radius: 5px;
    transition: all 0.2s ease-in-out
}
.sign-up{
    display: none;
}
.signup_btn{
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    border : none ;
}
.signup_btn:hover{
    background-color: #222;
    color: white;
    transition: all 0.2s ease-in-out;
}
.icons{
    display: none;
}

@media screen and (max-width : 850px) {
    .Navitems{
        z-index: 99;
    } 
    .nav_menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        border-radius: 13px;
        
    }
    
        .nav_menu.active{
            left: 0;
            opacity: 1;
            z-index:-1;
        }
        .nav-links{
           display: block;
           width: 100%;
           font-size: 1.2rem;
           padding: 2rem 0;
        }
        .nav-links:hover{
            background-color: rgb(0, 47, 201);
            color: white;
            transition: none ;
        }

        .icons {
            padding: auto;
            margin-left: 20px;
            display: flex;
        }
        
        .call-icon {
            width: 50px;
            height: 60px;
            margin-right: 20px; /* Add margin-right here */
        }
        
        .whatsapp-icon {
            width: 50px;
            height: 60px;
        }
        
        .signup_btn{
            display: block;
            text-align: center;
            padding: 1.5rem;
            margin: auto;
            border-radius: 4px;
            width: 80%;
            background-color: #222;
            text-decoration: none;
            color: white;
            font-size: 1.2rem;
            font-weight: 700;
            letter-spacing: 2px;
        }
    
}
