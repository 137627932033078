/* Styles for larger screens */
.button-group {
    cursor: pointer;
    padding: 10px;
    margin-top: 20px;
    display: none;
  }
  
  /* Styles for smaller screens */
  @media (max-width: 768px) {
    .button-group {
        cursor: pointer;
      display: flex;
      justify-content: center;
      gap: 10px;
      width: 100%;
    }
    .btn-outstation{
        flex: 1;
      padding: 10px;
      background-color:#013d65 ;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
    }
    .btn {
      flex: 1;
      padding: 10px;
      background-color: #013d65;
      color: #fff;
      font-size: 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
    }
  }