.booking-page {
    margin: 0 0px;
    padding: 2rem;
    background-color: #f2f2f2;
  }

  .booking-page h1 {
    font-size: 3rem;
    text-align: center;
  }
  .booking-page .booking-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
  
  .booking-page .form-card {
    background-color: #fff;
    flex: 1;
    max-width: 500px;
    padding: 2rem;
    border: 1px solid #ccc; /* Adjusted border color */
    border-radius: 10px;
  }
  
  .booking-page .form-group {
    margin-bottom: 1rem;
  }
  
  .booking-page label {
    display: block;
    font-weight: normal;
    margin-bottom: 0.5rem;
    text-align: left;
  }
  
  .booking-page input,
  .booking-page textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .booking-page textarea {
    height: 100px;
  }
  
  .booking-page .book-now-btn {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .booking-page .image-container {
    flex: 1;
    max-width: 600px;
  }
  
  .booking-page .image-container img {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .booking-page .booking-container {
      max-width: 100%;
    }
  
    .booking-page .image-container {
      display: none;
    }
  }
  